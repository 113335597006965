import * as d3 from 'd3'
import { useRef, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useMe } from '../providers/Me';
import { useQuery, gql } from '@apollo/client';
import {
  Card,
  CardContent,
  Typography,
  Grid,
} from '@mui/material'

import '../App.css'


export const ActivityGraphLegend = () => (
  <>
    <Grid container sx={{ mt: 2 }}>
      <Grid item xs={12} md={7}>
        <Card>
          <CardContent>
            <Typography variant="body1">LEGEND</Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid item pr={2} xs={2}><div style={{ marginTop: '.15rem', height: '1em', backgroundColor: 'rgb(51, 160, 44)'}}></div></Grid>
              <Grid item pr={2} xs={4}><Typography variant="body2">Completed</Typography></Grid>
              <Grid item pr={2} xs={2}><div style={{ marginTop: '.25rem', height: '1em', backgroundColor: 'rgb(31, 120, 180)'}}></div></Grid>
              <Grid item pr={2} xs={4}><Typography variant="body2">Pending</Typography></Grid>
              <Grid item pr={2} xs={2}><div style={{ marginTop: '.25rem', height: '1em', backgroundColor: 'rgb(178, 223, 138)'}}></div></Grid>
              <Grid item pr={2} xs={4}><Typography variant="body2">To do</Typography></Grid>
              <Grid item pr={2} xs={2}><div style={{ marginTop: '.25rem', height: '1em', backgroundColor: 'rgb(166, 206, 227)'}}></div></Grid>
              <Grid item pr={2} xs={4}><Typography variant="body2">Planned</Typography></Grid>
              <Grid item pr={2} xs={2}><div style={{ marginTop: '.25rem', height: '1em', backgroundColor: 'rgb(251, 154, 153)'}}></div></Grid>
              <Grid item pr={2} xs={4}><Typography variant="body2">To do (Urgent)</Typography></Grid>
              <Grid item pr={2} xs={2}><div style={{ marginTop: '.25rem', height: '1em', backgroundColor: 'black'}}></div></Grid>
              <Grid item pr={2} xs={4}><Typography variant="body2">Cancelled</Typography></Grid>                
              <Grid item pr={2} xs={2}><div style={{ height: '.8em', borderBottom: '2px dotted grey'}}></div></Grid>
              <Grid item pr={2} xs={4}><Typography variant="body2">Dependency</Typography></Grid>    
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </>
)


export const ActivityGraph = ({ ssid }) => {
  const ref = useRef()
  const { me } = useMe()
  const history = useHistory();
  const { data, loading, error } = useQuery(gql`
    query PatientActivityGraphData ( $ssid: String! ) {
      patientActivityGraphData ( ssid: $ssid ) {
        id
        overdue
        overdueDays
        assignedOrganization {
          id
        }
        state
        since
        prerequisiteActivityPks
        activity {
          id
          name
          description
          updateable
        }
      }
    }
  `, {
    variables: { ssid },
  })
  
  useEffect(() => {
    if (!data || !me || !ref.current || loading || error) {
      // activitiesGraph(ref.current, ssid, data.patientActivityGraphData, me)
      return;
    }
    
    const activities = data.patientActivityGraphData;

    var clone = objList => {
      var res = [];
      for (var i = 0; i < objList.length; i++) {
        var clone = {};
        for (var k in objList[i]) {
          clone[k] = objList[i][k];
        }
        res.push(clone);
      }
      return res;
    };

    var findActivity = (activityPk, activityGraphData) => {
      return activityGraphData.reduce(function(prev, curr, currIndex){
        if (currIndex === 0) return null;
        return (curr.activity.id === activityPk) ? curr : prev;
      });
      // for (let i = 0; i < activityGraphData.length; i++) {
      //   const pa = activityGraphData[i];
      //   if (pa.activity.id === activityPk) return pa;
      // }
      // return null;
    };
    
    /**
     * determine how many columns are in the data:
     */
    var countColumns = activityGraphData => {
      return activityGraphData.reduce(function(prev, curr, currIndex){
        return (curr.column >= prev) ? ( curr.column+1 ) : prev;
      },1);
    };

    /**
     * adds 
     *  .parents[]: list of parent activities
     *  .column (int)
     *  .row (int)
     *  .assigned_to_users_organization (bool)
     */
    var augment = activityGraphData => {
      // var currentColumn = 0;
      // var numCols = 0;
      var changed = false;
      var columnOrderCount = {};
      for (let i = 0; i < activityGraphData.length; i++) {
        let a = activityGraphData[i];
        a.parents = [];
        a.column = 0;
        a.assigned_to_users_organization = (a.assignedOrganization.id === me.organization.id);
        
        if (a.prerequisiteActivityPks.length > 0) {
          for (let m = 0; m < a.prerequisiteActivityPks.length; m++) {
            const parentActivity = findActivity(a.prerequisiteActivityPks[m], activityGraphData);
            a.parents.push(parentActivity);
          }
        }
      }

      var i, a;
      do {
        changed = false;
        // var i = 0, a = 0;
        for (i = 0; i < activityGraphData.length; i++) {
          a = activityGraphData[i];
          for (var pi = 0; pi < a.parents.length; pi++) {
            if (a.parents[pi].column >= a.column) {
              a.column++;
              changed = true;
              break;
            }
          }
          if (changed) break;
        }
      } while (changed)
      
      for (i = 0; i < activityGraphData.length; i++) {
        a = activityGraphData[i];
        if (!(a.column in columnOrderCount)) {
          columnOrderCount[a.column] = 0;
        } else {
          columnOrderCount[a.column]++;
        }
        a.row = columnOrderCount[a.column];
      }
                    
      return activityGraphData;
    };

    var createRowScales = function(activityGraphData) {
      var columnCounts = {};
      var columnOrdinals = {};

      for (var i = 0; i < activityGraphData.length; i++){
        var a = activityGraphData[i];
        if (!(a.column in columnCounts)) {
          columnCounts[a.column] = 0;
        }
        columnCounts[a.column]++;
      }

      for (var k in columnCounts) {
        columnOrdinals[k] = d3.scalePoint()
          .domain(d3.range(columnCounts[k]+1))
          .range([0, config.height]);
      }

      return columnOrdinals;
    };

    var extractPaths = function(activityGraphData) {
      var paths = [];
      for (var i = 0; i < activityGraphData.length; i++) {
        var a = activityGraphData[i];
        if (a.parents.length === 0) continue;
        for (var m = 0; m < a.parents.length; m++ ) {
          paths.push({
            rhs: a,
            lhs: a.parents[m]
          });
        }
      }
      return paths;
    };

    var config = {
      width: 1000,
      height: 450,
      ellipse: {
          rx: 20,
          ry: 10,
      }
    };

    var tooltip = d3.select("body")
        .append("div")
        .style('position', 'absolute')
        .style('z-index', 1070)
        .style('display', 'none')
        // .style('font-size','12px')
        .style('padding', '.5rem')
        .style('line-height', 1.4)
        .style('visibility', 'visible')
        .style('filter', 'alpha(opacity=0)')
        .style('opacity', 0)
        .style('color', 'white')
        .style('background-color', 'black')
        .html("");

    var updateTooltip = (patient_activity) =>{
      tooltip
        .style('opacity', 0.80)
        .style('display', 'block')

      var html = "<strong>" + patient_activity.activity.description + "</strong>";
      html += "<br />";
      if (patient_activity.state === "Succeeded") {
          html += "<strong> Completed on ";
          html += patient_activity.since;
          html += "</strong>";

          if (patient_activity.assigned_to_users_organization && patient_activity.activity.updateable) {
              html += "<br />";
              html += "<strong style='color: #00cc00'>Click to update info</strong>"
          }
      }

      if (patient_activity.state === "Activated") {
          html += "<strong> To do since ";
          html += patient_activity.since;
          html += "</strong>";

          if (patient_activity.assigned_to_users_organization) {
              html += "<br />";
              if (patient_activity.overdue) {
                  html += "<strong style='color: #cc0000'>Click to proceed</strong>";
              } else {
                  html += "<strong style='color: #00cc00'>Click to proceed</strong>";
              }
          }
      }
      tooltip.html(html);
    };
    var svg = d3.select(ref.current)
      .append(`svg`)
      .attr(`style`, `width: 100%;`);

    /**
     * 
     */
    var update = function() {
      // update the config for new/actual dimensions:
      svg.attr(`style`, `width: 100%`);

      config.width = svg.node().getBoundingClientRect().width
      // config.width = parseInt(svg.style("width"));

      var activityGraphData = augment(clone(activities));
      var numColumns = countColumns(activityGraphData);
      var rowScales = createRowScales(activityGraphData);
      var columnScale = d3.scalePoint()
        .domain(d3.range(numColumns+1))
        .range([0, config.width]);
      var colWidth = config.width / numColumns;
      var activityPaths = extractPaths(activityGraphData);

      var rowHeightFunc = function(column) {
        var rows = activityGraphData.filter(function(act){
          return act.column === column;
        });
        return config.height / rows.length;
      };

      svg
        .attr("height", config.height)
        .attr("width", config.width)

      var activityCircles = svg.selectAll("ellipse.activity")
        .data(activityGraphData, function(d,i){
          return d.activity.id;
        });
      
      var activityLines = svg.selectAll("line.dep-arrow")
        .data(activityPaths, function(d,i){
          return d.lhs.activity.id + ":" + d.rhs.activity.id;
        })
      
      var getX = function(d) {
        return parseInt(columnScale(d.column) + colWidth/2);
      };

      var getY = function(d) {
        var rowHeight = rowHeightFunc(d.column);
        var y = rowScales[d.column](d.row);
        return y + rowHeight/2;
      };

      var statusColorMap = function(activity) {
        if (activity.state === "Succeeded") return "rgb(51, 160, 44)";
        if (activity.state === "Cancelled") return "black";
        if (activity.state === "Pending") return "rgb(31, 120, 180)";
        if (activity.state === "Planned") return "rgb(166, 206, 227)";
        if (activity.state === "Activated") {
            if (activity.assigned_to_users_organization) {
                return (activity.overdue) ? "rgb(251, 154, 153)" : "rgb(178, 223, 138)";
            } else {
                return "rgb(31, 120, 180)";
            }
        }
        return "cyan"; // unknown status
      };
      activityLines
        .attr("x1", function(d) {
          return getX(d.lhs);
        })
        .attr("y1", function(d) {
          return getY(d.lhs);
        })
        .attr("x2", function(d) {
          return getX(d.rhs);
        })
        .attr("y2", function(d) {
            return getY(d.rhs);
        });
      activityLines.enter()
        .append("line")
          .classed("dep-arrow", true)
          .attr("stroke", "grey")
          .attr("stroke-width", 1)
          .attr("stroke-dasharray", "4,1")
          .attr("x1", function(d) {
            return getX(d.lhs);
          })
          .attr("y1", function(d) {
            return getY(d.lhs);
          })
          .attr("x2", function(d) {
            return getX(d.rhs);
          })
          .attr("y2", function(d) {
              return getY(d.rhs);
          });
        
      activityCircles
        .attr("transform", function(d){
          return "translate("+getX(d)+","+getY(d)+")"
        })
      
      activityCircles.enter()
        .append("ellipse")
        .classed("activity", true)
        .attr("rx", config.ellipse.rx)
        .attr("ry", config.ellipse.ry)
        .attr("fill", function(d){
            return statusColorMap(d)
        })
        .attr("transform", function(d){
            return "translate("+(getX(d))+","+(getY(d))+")";
        })
        .on("mousemove", function(event, d) {
          tooltip
            .style("left", (event.pageX + 20) + "px")
            .style("top", (event.pageY - 20) + "px")
        })
        .on("mouseover", function(event, d){
          updateTooltip(d);
          d3.select(this)
            .attr("stroke", statusColorMap(d))
            .attr("r", 17)
            .attr("fill", "white")
            .attr("stroke-width", 2)
            .attr("cursor", (d.assigned_to_users_organization && ((d.activity.updateable && d.state === "Succeeded") || d.state === "Activated" )) ? "pointer": "not-allowed")
              // "stroke-dasharray": "4,1",
        })
        .on("mouseout", function(event, d){
          tooltip
            .style('opacity', 0)
            .style('display', 'none')
          d3.select(this)
            .attr("r", 15)
            .attr("fill", statusColorMap(d))
            .attr("stroke-width", 0)
        })
        .on("click", function(event, d){
            if (d.assigned_to_users_organization) {
                if (d.state === "Activated") {
                    // document.location.href = `/patient/${ssid}/${d.activity.name}`;
                    history.push(`/patient/${ssid}/activity/${d.activity.name}`)
                } else if (d.state === "Succeeded" && d.activity.updateable) {
                    // document.location.href = `/patient/${ssid}/${d.activity.name}`;
                    history.push(`/patient/${ssid}/activity/${d.activity.name}`)
                }
            } else {
                if (me.isMonitor || me.isGroupMonitor) {
                    var activityElmId = "#activity_" + d.activity.id;
                    // moves to the element, scrolls up to see the title under the navbar, then
                    // replaces the hash with a non existing one to avoid a bug where a user clicks
                    // on the same anchor twice, the seocnd time the page doesnt scroll:
                    window.location.hash = activityElmId;
                    window.location.hash = 'patient_data';
                    window.scrollBy(0, -90);
                    d3.select(activityElmId)
                        .attr(`style`, 'background-color: #FFFF9C')
                        .transition()
                        .duration(2000)
                        .attr(`style`, 'background-color: #FFFFFF');
                }
            }
        });
    };

    (function(){
        // any resize events will result in a complete redraw of the graph:
        var resizeHandler = d3.select(window).on('resize');
        if (typeof resizeHandler !== "function") {
            resizeHandler = function(){};
        }
        d3.select(window).on('resize', function(){
            resizeHandler.apply(null, arguments);
            update();
        });
        update();
    })();

    return () => {
      svg.remove();
      tooltip.remove();
    }
  }, [data, error, loading, history, ssid,  me, ref])

  return (
    <>
      <div>    
          <div style={{
            width: '41%',
            borderBottom: '2px solid grey',
            float: 'left',
            margin: '0 1%',
            textAlign: 'center',
            fontSize: '1.2rem',
            fontWeight: 'bold',
          }}>Local Hospital</div>

          <div style={{
            width: '28%',
            borderBottom: '2px solid grey',
            float: 'left',
            textAlign: 'center',
            fontSize: '1.2rem',
            fontWeight: 'bold',
          }}>Central Lab</div>

          <div style={{
            width: '27%',
            borderBottom: '2px solid grey',
            float: 'left',
            margin: '0 1%',
            textAlign: 'center',
            fontSize: '1.2rem',
            fontWeight: 'bold',
          }}>Report</div>    
      </div>

      <div ref={ref} />
    </>
  )
}