import { useState, useEffect } from 'react'

// Found here: https://usehooks.com/useLocalStorage/
export const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });
    const setValue = (value) => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // 
        }
    };
    return [storedValue, setValue];
}


// Found here: https://usehooks.com/useDebounce/
export const useDebounce = (value, delay) => {
    // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export const useStateFilters = (initialStates) => {
  const [stateFilter, setStateFilter] = useState([...initialStates])
  const toggleStateFilter = state => {
    if (stateFilter.indexOf(state) === -1) {
        setStateFilter(stateFilter => [...stateFilter, state])
    } else {
        setStateFilter(stateFilter => stateFilter.filter(s => s !== state))
    }
  }
  return [stateFilter, toggleStateFilter]
}