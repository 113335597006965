import {
    Container,
    Card,
    CardContent,
    Typography,
    Box,
} from '@mui/material'

import { MyLink } from "./Widgets"


const ContactBlock = ({ name, email, title }) => (
    <Box sx={{ py: 2 }}>
        <Typography variant="body1">{name}</Typography>
        {title && (
            <Typography variant="body2" color="text.secondary">{title}</Typography>
        )}
        <MyLink href={`mailto:${email}`}>{email}</MyLink>
    </Box>
)

const ContactInfo = () => (
    <Container sx={{ mb: 2 }}>
        <Card>
            <CardContent>
                <Typography variant="h5">Contact</Typography>
                <ContactBlock name="The AURORA Team" email="aurora.bighq@bigagainstbc.org" />
                <ContactBlock name="Alice RAIMBAULT" email="alice.raimbault@bigaginstbc.org" title="Project Manager" />
                <ContactBlock name="Alexandre IRRTHUM" email="alexandre.irrthum@bigagainstbc.org" title="Senior Scientitic Advisor" />
                <ContactBlock name="Shean MASSEY" email="shean.massey@bigagainstbc.org" title="Software Developer" />
            </CardContent>
        </Card>
    </Container>
)

const Documents = () => (
    <Container sx={{ mb: 2 }}>
        <Card>
            <CardContent>
                <Typography variant="h5">Study Documents</Typography>

                <ul className="list-unstyled">
                    <li><MyLink href="/static/AURORA_protocol_v1_0.pdf?cb=20190222">Protocol v1.0</MyLink></li>
                    <li><MyLink href="/static/AURORA_protocol_v2_0.pdf?cb=20190222">Protocol v2.0</MyLink></li>
                    <li><MyLink href="/static/AURORA_protocol_v3_0.pdf?cb=20190222">Protocol v3.0</MyLink></li>
                </ul>

                <ul className="list-unstyled">
                    <li><MyLink href="/static/AURORA_PIS_ICF_v1_0.pdf?cb=20190222">PIS-ICF v1.0</MyLink></li>
                    <li><MyLink href="/static/AURORA_PIS_ICF_v1_1.pdf?cb=20190222">PIS-ICF v1.1</MyLink></li>
                    <li><MyLink href="/static/AURORA_PIS_ICF_v2_0.pdf?cb=20190222">PIS-ICF v2.0</MyLink></li>
                    <li><MyLink href="/static/AURORA_PIS_ICF_v3_0_new.pdf?cb=20190222">PIS-ICF v3.0 (new patients)</MyLink></li>
                    <li><MyLink href="/static/AURORA_PIS_ICF_v3_0_registered.pdf?cb=20190222">PIS-ICF v3.0 (registered patients)</MyLink></li>
                </ul>

                <ul className="list-unstyled">
                    <li><MyLink href="/static/AURORA_SAE_form.pdf?cb=20190222">SAE Form</MyLink></li>
                </ul>

                <ul className="list-unstyled">
                    <li><MyLink href="/static/AURORA_kit_resupply_form.pdf?cb=20190222">Kit Re-supply Form</MyLink></li>
                    <li><MyLink href="/static/AURORA_template_site_sig_log.pdf?cb=20190222">Site Signature Log Template</MyLink></li>
                </ul>

                <ul className="list-unstyled">
                    <li><MyLink href="/static/AURORA_lab_manual.pdf?cb=20190222">Lab Manual</MyLink></li>
                    <li><MyLink href="/static/AURORA_sample_flow_chart.pdf?cb=20190222">Samples Flowchart</MyLink></li>
                    <li><MyLink href="/static/AURORA_IT_platform_queries_manual.pdf?cb=20190222">Queries Manual</MyLink></li>
                </ul>

                {/**
                <Typography variant="h5">Newsletters</Typography>
                <ul class="list-unstyled">
                    <li><MyLink href="/static/AURORA_Newsletter_May_2017.pdf?cb=20190222">AURORA Newsletter May 2017</MyLink></li>
                    <li><MyLink href="/static/AURORA_Newsletter_September_2016.pdf?cb=20190222">AURORA Newsletter Sep 2016</MyLink></li>
                    <li><MyLink href="/static/AURORA_Newsletter_January_2016.pdf?cb=20190222">AURORA Newsletter Jan 2016</MyLink></li>
                </ul>
                */}
            </CardContent>
        </Card>
    </Container>
)

export const Help = () => (
    <>
        <ContactInfo />
    </>
)

export const StudyDocuments = () => {
    return <Documents />
}