import React, { useEffect, useState } from 'react'
import {
  Redirect,
  useParams,
} from 'react-router-dom';
import {
  Stack,
  Button,
  IconButton,
  Divider,
  Paper,
  Alert,
  Box,
} from '@mui/material'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Settings } from '../Settings'

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  ConfirmDialog,
  Loading, MyLink, MyTextField,
} from './Widgets'

export const AuthenticationRequired = ({ children }) => {
  const [verifyToken] = useMutation(gql`
    mutation VerifyToken ( $token: String! ) {
      verifyToken(token: $token) {
        payload
      }
    }
  `)

  const [cycleAccessToken] = useMutation(gql`
    mutation CycleAccessToken {
      cycleAccessToken {
        token
      }
    }`)

  const [showLoginForm, setShowLoginForm] = useState(true)
  const [initialLoading, setInitialLoading] = useState(true)

  useEffect(() => {
    const updateAccessTokenFunc = async () => {
      let response;
      try {
        response = await cycleAccessToken();
        localStorage.setItem(Settings.ACCESS_TOKEN, response.data.cycleAccessToken.token)
      } catch (e) {
        console.log(e)
        localStorage.removeItem(Settings.ACCESS_TOKEN)
        setShowLoginForm(true)
      }
    }
    const interval = setInterval(() => {
      if (localStorage.getItem(Settings.ACCESS_TOKEN)) {
        updateAccessTokenFunc()
      }
    }, 1000 * 60) // every minute 
    updateAccessTokenFunc();
    return () => clearInterval(interval)
  }, [ cycleAccessToken ])

  useEffect(() => {
    const token = localStorage.getItem(Settings.ACCESS_TOKEN)
    if (!token) {
      setShowLoginForm(true);
      setInitialLoading(false);
      return;
    }
    (async function useEffectInner() {
      let response
      try {
        response = await verifyToken({ variables: { token } })
        setInitialLoading(false)
      } catch (e) {
        setShowLoginForm(true)
        setInitialLoading(false)
        localStorage.removeItem(Settings.ACCESS_TOKEN)
        return
      }
      if (response.errors && response.errors.length > 0) {
        setShowLoginForm(true)
        localStorage.removeItem(Settings.ACCESS_TOKEN)
        return
      }
      setShowLoginForm(false);
    })()
  }, [verifyToken])

  if (initialLoading) return <Loading />
  if (showLoginForm) return <LoginScreen />
  return children
}

export const AuthHandlerComponent = () => {
  const { token } = useParams()
  localStorage.setItem(Settings.ACCESS_TOKEN, token);
  return <Redirect to={sessionStorage.getItem("loginUrl") === "/" ? Settings.HOME_PAGE : sessionStorage.getItem("loginUrl")} />
}
  
export const LoginScreen = () => {
  // const { enqueueSnackbar } = useSnackbar()
  // useEffect(() => {
  //   enqueueSnackbar('test test')
  // }, [])

  sessionStorage.setItem("loginUrl", window.location.pathname)

  const [blocked, setBlocked] = useState(false)

  const [tokenAuth, { loading, error, data, reset }] = useMutation(gql`
    mutation TokenAuthMutation ( $username: String!, $password: String! ) {
      tokenAuth ( username: $username, password: $password ) {
        token
      }
    }
  `)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')

  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (error) {
      setBlocked(true)
      setTimeout(() => {
        setBlocked(false)
        reset()
      }, 1000 * 5) // 5 minutes
    }
  }, [error, reset])

  useEffect(() => {
    if (data) {
      window.location.href = `/auth/${data.tokenAuth.token}`
    }
  }, [data])

  const [showRequestAccessDialog, setShowRequestAccessDialog] = useState(false)
  const [showPasswordReset, setShowPasswordReset] = useState(false)

  return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Paper sx={{ padding: 2, boxShadow: '5px 5px 10px rgba(0,0,0,.5)' }}>
        <Stack direction="row" sx={{ mb: 4 }}>
          <img alt="" style={{ maxWidth: 250 }} src="https://platform.aurora-mbc.org/static/img/Aurora_couleur_JPG.jpg" />
          <img alt="" style={{ maxWidth: 250 }} src="https://platform.aurora-mbc.org/static/img/BIG_logo.jpg" />
        </Stack>       
        <Stack direction="row" spacing={2}>
          <Stack spacing={2} direction="column" alignItems="center" justifyContent="center">
            <MyTextField
              label="Username"
              onChange={e => setUsername(e.target.value)}
              />
            <MyTextField
              label="Password"
              type={showPassword ? "text" : "password"}
              onChange={e => setPassword(e.target.value)}
              InputProps={{ endAdornment: <>
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </>}}
              />
            <Button
              variant="outlined"
              disabled={blocked || loading}
              onClick={() => {
                tokenAuth({ variables: { username, password } })
                  .then(({ data }) => {
                    
                  })
                  .catch((e) => {
                    console.log('error', e)
                  })
              }}
              >login</Button>
          </Stack>
          <Divider orientation="vertical" flexItem/>
          <Stack spacing={3} direction="column" alignItems="center" justifyContent="center">
            <MyLink href="#" onClick={() => setShowPasswordReset(true)}>Forgot your password?</MyLink>
            <MyLink href="#" onClick={() => setShowRequestAccessDialog(true)}>Request access</MyLink>
          </Stack>
        </Stack>
        {error && (
          <Alert sx={{ mt: 2 }} severity="warning">
            AUTHENTICATION ERROR!
          </Alert>
        )}
      </Paper>
      <ConfirmDialog
        open={showRequestAccessDialog}
        onClose={() => setShowRequestAccessDialog(false)}
        onSubmit={null}
        title="Request access"
        body="Please contact the administrator to request access."
        />
      <ConfirmDialog
        open={showPasswordReset}
        onClose={() => setShowPasswordReset(false)}
        title="Forgot your password?"
        body={
          <Box sx={{ pt: 2 }}>
            <MyTextField
              label="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              />
          </Box>
        }
        onSubmit={() => {
          setShowPasswordReset(false)
          // @TODO call send email mutation (and double check all the mail content)
        }}
        />
    </div>
  )
}
