import { styled } from '@mui/material/styles'
import {
    // Container,
    // Autocomplete,
    CircularProgress,
    // Divider,
    TextField,
    Card,
    Button,
    Grid,
    // Item,
    FormControl,
    Typography,
    InputLabel,
    Select,
    MenuItem,

    Link,

    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,

    Stack,

} from '@mui/material'
import { useState, useEffect } from 'react'
import { Link as RouterLink, NavLink } from 'react-router-dom'

// import TextField from '@mui/material/TextField';


// export const MyNavLink = props => <NavLink
//     variant="body1"
//     underline="none"
//     component={RouterLink}
//     style={isActive => {
//         console.log('navlink isActive', isActive)
//     }}
//     {...props}
//     />

export const MyNavLink = props => <Link
    variant="body1"
    underline="none"
    component={RouterLink}
    {...props}
    />

export const MyLink = props => <Link
    // component="button"
    variant="body1"
    underline="none"
    component="a"
    {...props}
    />


export const AppBarButton = ({ to, label }) => (
    <Button
        color="inherit"
        component={NavLink}
        to={to}
        sx={{
            '&.active': {
                backgroundColor: 'secondary.dark',
            },
        }}
        >
        {label}
    </Button>
)

export const Loading = () => <CircularProgress />

export const MyTextField = props => <TextField fullWidth variant="outlined" {...props} />

export const MyCard = props => styled(Card)(({ theme }) => ({
    marginBottom: '1rem',
    '& .MuiCardHeader-title': {
        // fontSize: "1.1rem",
        fontWeight: 'bold',
        // color: theme.palette.text.primary,
    },
    '& .MuiCardContent-root': {
        padding: '1rem',
    },
    '& a': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textDecoration: 'none',
    }
}))

export const DebugVar = ({ name, value }) => {
    const [visible, setVisible] = useState(false)
    return visible ? (
        <div style={{ overflowX: 'scroll' }}>
            <Button variant="outlined" onClick={() => setVisible(false)}>hide</Button>
            <pre>{JSON.stringify(value, null, 2)}</pre>
        </div>
    ) : (
        <Button variant="outlined" onClick={() => setVisible(true)}>show value of {name}</Button>
    );
}

export const ConfirmDialog = ({ open, title, body, onClose, onSubmit }) => {
    return <>
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth='sm'
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} autoFocus>Cancel</Button>
                {onSubmit && <Button onClick={onSubmit} color="primary" autoFocus>Confirm</Button>}
            </DialogActions>
        </Dialog>
    </>
}

export const SelectDate = ({ onChange, value, label, helperText }) => {
    const [year, setYear] = useState("")
    const [month, setMonth] = useState("")
    const [day, setDay] = useState("")

    // const yearChoices = [2022, 2021, 2020]
    const d = new Date();
    const yearChoices = Array(100).fill(0).map((x, y) => d.getFullYear() - (x + y))

    const monthChoices = [
        { value: '01', label: 'Jan' },
        { value: '02', label: 'Feb' },
        { value: '03', label: 'Mar' },
        { value: '04', label: 'Apr' },
        { value: '05', label: 'May' },
        { value: '06', label: 'Jun' },
        { value: '07', label: 'Jul' },
        { value: '08', label: 'Aug' },
        { value: '09', label: 'Sep' },
        { value: '10', label: 'Oct' },
        { value: '11', label: 'Nov' },
        { value: '12', label: 'Dec' },
    ]

    const dayChoices = "01,02,03,04,05,06,07,08,09,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31".split(",")

    useEffect(() => {
        if (year && month && day) {
            onChange( `${year}-${month}-${day}` )
        }
    }, [year, month, day, onChange])

    useEffect(() => {
        if (value) {
            const [y, m, d] = value.split("-");
            if (year !== y) setYear(y);
            if (month !== m) setMonth(m);
            if (day !== d) setDay(d)
        }
    }, [value])

    return (
        <>
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12}>
                    <Typography>{label}</Typography>
                </Grid>

                <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel>Year</InputLabel>
                            <Select
                                value={year}
                                label={"Year"}
                                onChange={e => setYear(e.target.value)}
                                >
                                {yearChoices.map(choice => (
                                    <MenuItem key={choice} value={choice}>{choice}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                </Grid>
                <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel>Month</InputLabel>
                            <Select
                                value={month}
                                label={"Month"}
                                onChange={e => setMonth(e.target.value)}
                                >
                                {monthChoices.map(choice => (
                                    <MenuItem key={choice.value} value={choice.value}>{choice.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                </Grid>
                <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel>Day</InputLabel>
                            <Select
                                value={day}
                                label={"Day"}
                                onChange={e => setDay(e.target.value)}
                                >
                                {dayChoices.map(choice => (
                                    <MenuItem key={choice} value={choice}>{choice}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                </Grid>

                {helperText && (
                    <Grid item xs={12}>
                        <Typography>{helperText}</Typography>
                    </Grid>
                )}
            </Grid>
        </>
    )
}