import { useQuery, gql, useMutation } from "@apollo/client"
import { Loading, MyTextField } from "./Widgets"
import {
    Stack,
    Container,
    Card,
    CardContent,
    TablePagination,
    Typography,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Divider,
    Button,
    Grid,
    Box,
    Link,
    IconButton,
    Collapse,
    Paper,
    Alert,
    Chip,
} from '@mui/material'
import { useHistory, useParams } from "react-router-dom";
import { useState, useContext, useEffect, useReducer } from "react";
import { useDebounce } from "../Hooks";
import { useMe } from "../providers/Me";

// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const OPENED = 1
const RESOLVED = 2
const CLOSED = 3

const QUERY_STATE_LABELS = [
    null,
    'Pending',
    'Answered',
    'Closed',
]

const QUERY_STATE_CHIP_COLORS = [
    null,
    'primary',
    'success',
    'default',
]

export const QueryDetails = () => {
    const { me } = useMe();
    const { id } = useParams()
    const history = useHistory()
    const { data, loading, error, refetch } = useQuery(gql`
        query QueryDetails ( $id: ID! ) {
            query ( id: $id ) {
                id
                state
                patient {
                    id
                    integrateIdentifier
                }
                createdBy {
                    id
                    title
                    lastName
                    organization {
                        id
                        breastCode
                        shortName
                    }
                }
                patientActivities {
                    id
                    activity {
                        id
                        name
                        displayName
                    }
                }
                patientAttributes {
                    id
                    attribute {
                        id
                        name
                        displayName
                        activity {
                            id
                            name
                            displayName
                        }
                    }
                    value
                }
                comments {
                    id
                    text
                    createdAt
                    createdBy {
                        id
                        title
                        firstName
                        lastName
                        organization {
                            id
                            breastCode
                            shortName
                            fullName
                        }
                    }
                }
            }
        }
    `, {
        variables: { id },
    })

    const [ comment, setComment ] = useState('')

    const [ reopenQuery ] = useMutation(gql`
        mutation ReopenQueryMutation ( $id: ID!, $comment: String! ) {
            reopenQuery ( id: $id, comment: $comment ) {
                id
                state
            }
        }
    `)

    const [ closeQuery ] = useMutation(gql`
        mutation CloseQueryMutation ( $id: ID!, $comment: String ) {
            closeQuery ( id: $id, comment: $comment ) {
                id
                state
            }
        }
    `)

    const [ resolveQuery ] = useMutation(gql`
        mutation ResolveQueryMutation ( $id: ID!, $comment: String! ) {
            resolveQuery ( id: $id, comment: $comment ) {
                id
                state
            }
        }
    `)

    if (loading) return <Loading />
    if (error) return 'error'

    const { query } = data
    const { patient, comments, patientActivities, patientAttributes } = query

    const commentsLength = comments.length

    const showResolveQuery = ((me.isInvestigator || me.isNurse) && query.state === OPENED)
    const showReopenQuery = (me.isMonitor && (query.state === RESOLVED))
    const showCloseQuery = (me.isMonitor && (query.state === OPENED || query.state === RESOLVED))

    const submit = func => () => {
        func({ variables: {id: query.id, comment }})
            .then(() => {
                refetch()
                setComment('')
            });
    }

    return (
        <>
            <Container>
                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <Typography variant="h6">
                            Patient {patient.integrateIdentifier}
                            <Box component="span" sx={{ color: 'text.disabled' }}> | Query {query.id.padStart(4, '0')}</Box>
                        </Typography>
                        <Chip
                            label={QUERY_STATE_LABELS[query.state]}
                            color={QUERY_STATE_CHIP_COLORS[query.state]}
                            />
                    </CardContent>
                </Card>

                <Card>
                    <CardContent>
                            {comments.map((comment, commentIndex) => (
                                <div key={commentIndex}
                                    style={{
                                    display: 'block',
                                    width: '100%',
                                    // marginBottom: '2rem',
                                    minHeight: '5rem',
                                }}>
                                    <Stack direction="row">
                                        <div style={{ minWidth: '15rem', maxWidth: '15rem' }}>
                                            <Typography variant="body2" color="text.secondary">{comment.createdAt}</Typography>
                                            <Typography variant="body1">{comment.createdBy.title} {comment.createdBy.lastName}</Typography>

                                            {comment.createdBy.organization.breastCode
                                                ? <>
                                                    <Typography variant="body2" noWrap>{comment.createdBy.organization.fullName}</Typography>
                                                    <Typography variant="body2" color="text.secondary">{comment.createdBy.organization.breastCode}</Typography>
                                                  </>
                                                : <>
                                                    <Typography variant="body2">{comment.createdBy.organization.fullName}</Typography>
                                                  </>
                                            }
                                        </div>

                                        <div>
                                            <Typography variant="body1">{comment.text}</Typography>
                                            {commentIndex === 0 && (
                                                <Stack direction="row" spacing={4} py={2}>
                                                    {(patientActivities.length > 0) && (
                                                        <Box sx={{ border: '1px solid', p: 1 }}>
                                                            <Typography variant="body1">Concerned page{patientActivities.length > 1 && <>s</>}:</Typography>
                                                            <Stack direction="column">
                                                                {patientActivities.map(pa => (
                                                                    <Link
                                                                        key={pa.id}
                                                                        variant="body1" underline="none" sx={{ color: "primary", cursor: 'pointer' }}
                                                                        onClick={() => history.push(`/patient/${patient.integrateIdentifier}/activity/${pa.activity.name}`)}
                                                                        >
                                                                        {pa.activity.displayName}
                                                                    </Link>
                                                                ))}
                                                            </Stack>
                                                        </Box>
                                                    )}

                                                    {(patientAttributes.length > 0) && (
                                                            <Box sx={{ border: '1px solid', p: 1 }}>
                                                                <Typography variant="body1">Concerned field{patientAttributes.length > 1 && <>s</>}:</Typography>
                                                                <Stack direction="column">
                                                                    {patientAttributes.map(av => (
                                                                            <Link key={av.id} variant="body1" underline="none" sx={{ color: "primary", cursor: 'pointer' }}
                                                                                onClick={() => history.push(`/patient/${patient.integrateIdentifier}/activity/${av.attribute.activity.name}`)}
                                                                                >
                                                                                {av.attribute.displayName}
                                                                            </Link>
                                                                    ))}
                                                                </Stack>
                                                            </Box>
                                                    )}
                                                </Stack>
                                            )}
                                        </div>
                                    </Stack>

                                    {commentIndex !== (commentsLength - 1) && (
                                        <Divider sx={{ my: 2 }} />
                                    )}
                                </div>
                            ))}
                    </CardContent>
                </Card>

                {(showResolveQuery || showReopenQuery || showCloseQuery) && (
                    <Card sx={{ mt: 2 }}>
                        <CardContent>
                            <Box>
                                <MyTextField
                                    label="Add a comment to this thread"
                                    multiline
                                    autoFocus
                                    rows={3}
                                    value={comment}
                                    onChange={e => setComment(e.target.value)}
                                    />

                                <Stack sx={{ mt: 1 }} spacing={1} direction="row">
                                    {showResolveQuery && <Button variant="outlined" color="primary" onClick={submit(resolveQuery)}>Resolve query</Button>}
                                    {showReopenQuery && <Button variant="outlined" color="primary" onClick={submit(reopenQuery)}>Reopen</Button>}
                                    {showCloseQuery && <Button variant="outlined" color="primary" onClick={submit(closeQuery)}>Close</Button>}
                                </Stack>
                            </Box>
                        </CardContent>
                    </Card>
                )}
            </Container>
        </>
    )
}


export const PatientQueryListing = ({ ssid }) => {
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState("")
    const debouncedSearchTerm = useDebounce(searchTerm, 350)
    const [searchArgs, setSearchArgs] = useState({
        q: debouncedSearchTerm,
        skip: 0,
        first: 10,
    })
    const PENDING = 1
    const ANSWERED = 2
    const CLOSED = 3
    const [queryStateFilter, setQueryStateFilter] = useState([PENDING, ANSWERED, CLOSED])
    const toggleQueryStateFilter = state => {
        if (queryStateFilter.indexOf(state) === -1) {
            setQueryStateFilter([...queryStateFilter, state])
        } else {
            setQueryStateFilter(queryStateFilter.filter(s => s !== state))
        }
    }
    const { data, loading, error } = useQuery(gql`
        query QueryListing ( $ssid: String!, $searchArgs: SearchQueryInput!, $stateFilter: [Int!]! ) {
            patient (ssid: $ssid, attributes: []) {
                id
                integrateIdentifier
                site {
                    id
                    breastCode
                }
            }
            queries ( ssid: $ssid, searchArgs: $searchArgs, stateFilter: $stateFilter ) {
                page
                count
                results {
                    id
                    originalComment
                    createdBy {
                        id
                        firstName
                        lastName
                        title
                    }
                    createdAt
                }
            }
        }
    `, {
        variables: {
            ssid,
            searchArgs,
            stateFilter: queryStateFilter,
        },
        fetchPolicy: 'no-cache'
    })
    useEffect(() => {
        setSearchArgs({...searchArgs, q: debouncedSearchTerm, skip: 0 })
    }, [debouncedSearchTerm])
    if (error) return 'error'
    const patient = data?.patient
    const queries = data?.queries
    const results = data?.queries?.results
    
    if (patient && queries && queries.count === 0) return ''

    return (
        <Card sx={{ mt: 2 }}>
            <CardContent>
                <Stack direction="column" spacing={2}>
                    <MyTextField
                        label={`Search by query ID ...`}
                        value={searchTerm}
                        onChange={e => {
                            setSearchTerm(e.target.value)
                        }}
                        autoFocus
                    />
                    <FormGroup row sx={{ mb: 10 }}>
                        <FormControlLabel control={<Checkbox onChange={() => toggleQueryStateFilter(PENDING)} color="primary" checked={queryStateFilter.indexOf(PENDING) > -1} />} labelPlacement="end" label="Pending" />
                        <FormControlLabel control={<Checkbox onChange={() => toggleQueryStateFilter(ANSWERED)} color="primary" checked={queryStateFilter.indexOf(ANSWERED) > -1} />} labelPlacement="end" label="Answered" />
                        <FormControlLabel control={<Checkbox onChange={() => toggleQueryStateFilter(CLOSED)} color="primary" checked={queryStateFilter.indexOf(CLOSED) > -1} />} labelPlacement="end" label="Closed" />
                    </FormGroup>
                    {loading
                        ? (<Loading />)
                        : (
                            <>
                                {results.length === 0
                                    ? (<Typography variant="body1">No results found.</Typography>)
                                    : (
                                        <>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>ID</TableCell>
                                                        <TableCell>Text</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {results.map(r => (
                                                        <TableRow key={r.id} hover sx={{ cursor: 'pointer' }} onClick={() => {
                                                            history.push(`/query/${r.id}`)
                                                        }}>
                                                            <TableCell>{r.id}</TableCell>
                                                            <TableCell>
                                                                <Stack direction="column">
                                                                    {r.originalComment}
                                                                    <Typography color="text.secondary" variant="body2">Created by {r.createdBy.title} {r.createdBy.lastName} on {r.createdAt}</Typography>
                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                            <TablePagination
                                                component="div"
                                                count={queries.count}
                                                page={queries.page}
                                                rowsPerPage={searchArgs.first}
                                                onPageChange={(e, newPage) => {
                                                    setSearchArgs({ ...searchArgs, skip: newPage * searchArgs.first })
                                                }}
                                                onRowsPerPageChange={e => {
                                                    setSearchArgs({ ...searchArgs, first: e.target.value })
                                                }}
                                            />
                                        </>
                                    )}
                            </>
                        )
                    }
                </Stack>
            </CardContent>
        </Card>
    )
}


export const QueryListing = () => {
    // const { me } = useMe()
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState("")
    const debouncedSearchTerm = useDebounce(searchTerm, 350)
    const [searchArgs, setSearchArgs] = useState({
        q: debouncedSearchTerm,
        skip: 0,
        first: 10,
    })

    const PENDING = 1
    const ANSWERED = 2
    const CLOSED = 3

    const [queryStateFilter, setQueryStateFilter] = useState([PENDING, ANSWERED])
    
    const toggleQueryStateFilter = state => {
        if (queryStateFilter.indexOf(state) === -1) {
            setQueryStateFilter([...queryStateFilter, state])
        } else {
            setQueryStateFilter(queryStateFilter.filter(s => s !== state))
        }
    }

    const { data, loading, error } = useQuery(gql`
        query QueryListing ( $searchArgs: SearchQueryInput!, $stateFilter: [Int!]! ) {
            queries ( searchArgs: $searchArgs, stateFilter: $stateFilter ) {
                page
                count
                results {
                    id
                    originalComment
                    patient {
                        id
                        integrateIdentifier
                        site {
                            id
                            breastCode
                        }
                    }
                    createdBy {
                        id
                        firstName
                        lastName
                        title
                    }
                    createdAt
                }
            }
        }
    `, {
        variables: {
            searchArgs,
            stateFilter: queryStateFilter,
        },
        fetchPolicy: 'no-cache'
    })

    useEffect(() => {
        setSearchArgs({...searchArgs, q: debouncedSearchTerm, skip: 0 })
    }, [debouncedSearchTerm])

    if (error) return 'error'
    const queries = data?.queries
    const results = data?.queries?.results
    return (
        <Container>
            <Card>
                <CardContent>
                    <Stack direction="column" spacing={2}>
                        <MyTextField
                            label={`Search by query ID, patient ID, site, ...`}
                            value={searchTerm}
                            onChange={e => {
                                setSearchTerm(e.target.value)
                            }}
                            autoFocus
                        />

                        <FormGroup row sx={{ mb: 10 }}>
                            <FormControlLabel control={<Checkbox onChange={() => toggleQueryStateFilter(PENDING)} color="primary" checked={queryStateFilter.indexOf(PENDING) > -1} />} labelPlacement="end" label="Pending" />
                            <FormControlLabel control={<Checkbox onChange={() => toggleQueryStateFilter(ANSWERED)} color="primary" checked={queryStateFilter.indexOf(ANSWERED) > -1} />} labelPlacement="end" label="Answered" />
                            <FormControlLabel control={<Checkbox onChange={() => toggleQueryStateFilter(CLOSED)} color="primary" checked={queryStateFilter.indexOf(CLOSED) > -1} />} labelPlacement="end" label="Closed" />
                        </FormGroup>

                        {loading
                            ? (<Loading />)
                            : (
                                <>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ID</TableCell>
                                                <TableCell>Patient</TableCell>
                                                <TableCell>Site</TableCell>
                                                <TableCell>Text</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {results.map(r => (
                                                <TableRow key={r.id} hover sx={{ cursor: 'pointer' }} onClick={() => {
                                                    // @TODO!!!
                                                    history.push(`/query/${r.id}`)
                                                }}>
                                                    <TableCell>{r.id}</TableCell>
                                                    <TableCell>{r.patient.integrateIdentifier}</TableCell>
                                                    <TableCell>{r.patient.site.breastCode}</TableCell>
                                                    <TableCell>
                                                        <Stack direction="column">
                                                            {r.originalComment}
                                                            <Typography color="text.secondary" variant="body2">Created by {r.createdBy.title} {r.createdBy.lastName} on {r.createdAt}</Typography>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        component="div"
                                        count={queries.count}
                                        page={queries.page}
                                        rowsPerPage={searchArgs.first}
                                        onPageChange={(e, newPage) => {
                                            setSearchArgs({ ...searchArgs, skip: newPage * searchArgs.first })
                                        }}
                                        onRowsPerPageChange={e => {
                                            setSearchArgs({ ...searchArgs, first: e.target.value })
                                        }}
                                    />
                                </>
                            )
                        }
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    )
}


const formatRawValue = v => {
    if (v === null || v === undefined || v === '') return <Box component="span" sx={{ color: 'text.disabled' }}>blank</Box>
    if (typeof v === 'boolean') return v ? 'Yes' : 'No';
    return String(v)
}

const CollapsableRow = ({ row, state, dispatch }) => {
    // const [open, setOpen] = useState(false)

    const open = state.patientActivityIds.includes(row.id)

    return (
        <>
            <TableRow onClick={() => dispatch({ type: 'toggle_patient_activity', id: row.id })}>
                <TableCell padding="checkbox">
                    <IconButton size="small">
                        <Checkbox checked={open} />
                    </IconButton>
                </TableCell>
                <TableCell>
                    {row.activity.displayName}
                </TableCell>
                <TableCell>
                    {row.state}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ m: 2, border: 1, borderRadius: 1, p: 2 }}>

                            {/**
                            <Typography variant="h6" gutterBottom component="div" padding="checkbox">
                                <Checkbox />
                                {row.activity.displayName}
                            </Typography>
                            */}

                            {row.attributeValues.length === 0
                                ? (
                                    <></>
                                ) : (
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>Attribute</TableCell>
                                                <TableCell>Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {row.attributeValues.map(av => (
                                                <TableRow key={av.id} onClick={() => dispatch({ type: 'toggle_attribute_value', id: av.id })}>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox checked={state.attributeValueIds.includes(av.id)} />
                                                    </TableCell>
                                                    <TableCell>{av.attribute.displayName}</TableCell>
                                                    <TableCell>{formatRawValue(av.value)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

const queryReducer = (state, action) => {
    switch (action.type) {
        case 'toggle_attribute_value':
            return {
                ...state,
                attributeValueIds: state.attributeValueIds.includes(action.id)
                    ? state.attributeValueIds.filter(id => id !== action.id)
                    : [...state.attributeValueIds, action.id]
            }
        case 'toggle_patient_activity':
            return {
                ...state,
                patientActivityIds: state.patientActivityIds.includes(action.id)
                    ? state.patientActivityIds.filter(id => id !== action.id)
                    : [...state.patientActivityIds, action.id]
            }
        case 'set_comment':
            return {
                ...state,
                comment: action.comment
            }
        default:
            throw new Error();
    }
}

export const CreatePatientQuery = ({ ssid }) => {
    const history = useHistory()

    const [state, dispatch] = useReducer(queryReducer, {
        ssid,
        attributeValueIds: [],
        patientActivityIds: [],
        comment: '',
    })

    const { data, loading, error } = useQuery(gql`
        query PatientAttributeValues ($ssid: ID!) {
            queryDataTables (ssid: $ssid) {
                id
                state
                activity {
                    id
                    name
                    displayName
                }
                attributeValues {
                    id
                    attribute {
                        id
                        name
                        displayName
                    }
                    value
                }
            }
        }
    `, { variables: { ssid }})

    const [ createQuery, { error: createQueryError } ] = useMutation(gql`
        mutation CreatePatientQueryMutation ( $ssid: ID!, $attributeValueIds: [ID!]!, $patientActivityIds: [ID!]!, $comment: String! ) {
            createQuery ( ssid: $ssid, attributeValueIds: $attributeValueIds, patientActivityIds: $patientActivityIds, comment: $comment ) {
                id
                state
            }
        }
    `)

    const isValid = state.comment.length >= 2;

    const onSubmit = () => {
        createQuery({ variables: { ...state }})
            .then(({data}) => {
                console.log('data', data)
                const { id } = data.createQuery
                history.push(`/query/${id}`)
            })
    }

    if (loading) return <Loading />
    if (error) return 'error...'

    return (
        <Paper sx={{ my: 2 }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Activity</TableCell>
                        <TableCell>State</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.queryDataTables.map((row, i) => <CollapsableRow key={i} row={row} state={state} dispatch={dispatch} /> )}
                </TableBody>
            </Table>

            <Box sx={{ p: 2 }}>
                <form onSubmit={onSubmit}>
                    <MyTextField
                        label="Query comment"
                        multiline
                        rows={5}
                        fullWidth
                        value={state.comment}
                        onChange={e => dispatch({ type: 'set_comment', comment: e.target.value })}
                        sx={{ mb: 2 }}
                        />

                    <Button
                        disabled={!isValid}
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        >Create query</Button>

                    {createQueryError && <Alert severity="error">Error submitting form.</Alert>}
                </form>

                <pre>{JSON.stringify(state, null, 2)}</pre>
            </Box>
        </Paper>
    )
}