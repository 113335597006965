import { useQuery, gql, useMutation } from "@apollo/client"
import {
    Loading,
    // MyCard,
    MyTextField,
} from "./Widgets"

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Typography,
    Container,
    Card,
    // CardHeader,
    CardContent,
    Stack,

    Box,
    // Tab,
    // Tabs,
    // TabPanel,

    AppBar,
    Toolbar,
    Button,

    Paper,
    MenuItem,

    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Checkbox,
    ListItemText,

    Alert,

    Autocomplete,
    TextField,

    Menu,
    Fade,

    Divider,
    // IconButton,
} from '@mui/material'

import HandymanIcon from '@mui/icons-material/Handyman';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import FileUploadIcon from '@mui/icons-material/FileUpload';

import { useDebounce } from "../Hooks"
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch, NavLink } from "react-router-dom"
import { useState, useEffect } from "react"
import { Settings } from "../Settings"
import { useMe } from "../providers/Me";

import { MyLink, MyNavLink, AppBarButton } from "./Widgets";


export const Organizations = () => {
}

export const OrganizationList = () => {
    const history = useHistory()
    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 350)
    const [searchArgs, setSearchArgs] = useState({
        q: debouncedSearchTerm,
        skip: 0,
        first: 10,
    })
    useEffect(() => {
        setSearchArgs(s => ({...s, q: debouncedSearchTerm, skip: 0 }))
    }, [debouncedSearchTerm])

    const { data, loading, error } = useQuery(gql`
        query ListOrganizationsQuery ( $searchArgs: SearchOrganizationInput! ) {
            organizations ( searchArgs: $searchArgs ) {
                page
                count
                results {
                    id
                    shortName
                    fullName
                    breastCode
                    isSite
                    category
                    biguserSet {
                        id
                        username
                        title
                        firstName
                        lastName
                        isActive
                    }
                }
            }
        }
    `, { variables: { searchArgs }})

    // if (loading) return <Loading />
    if (error) return 'error...'
    
    const organizations = data?.organizations?.results || [];

    return (
        <Container>
            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Stack direction="column" spacing={2}>
                        <MyTextField
                            label={`Search for an organization`}
                            value={searchTerm}
                            onChange={e => {
                                setSearchTerm(e.target.value)
                            }}
                            autoFocus
                            />
                        {loading ? <Loading /> : (
                            <>
                                <Table size="small">
                                    <TableHead>
                                        <TableCell>Organization Name</TableCell>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Breast code</TableCell>
                                        <TableCell sx={{ textAlign: 'right' }}># of users</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {organizations.map(o => (
                                            <TableRow key={o.id} hover sx={{ cursor: 'pointer' }} onClick={() => {
                                                history.push(`/operations/organizations/${o.id}/profile`)
                                            }}>
                                                <TableCell>{o.fullName}</TableCell>
                                                <TableCell>{o.category}</TableCell>
                                                <TableCell>{o.isSite ? (o.breastCode ? o.breastCode : <Box component="span" sx={{ textWeight: 'bold' }}>UNKNOWN</Box>) : <Box component="span" sx={{ color: 'text.disabled' }}>N/A</Box>}</TableCell>
                                                <TableCell sx={{ textAlign: 'right' }}>{o.biguserSet.filter(u => u.isActive).length}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={data.organizations.count}
                                    page={data.organizations.page}
                                    rowsPerPage={searchArgs.first}
                                    rowsPerPageOptions={[ 10, 25, 50, 100, { value: -1, label: "ALL" }]}
                                    onPageChange={(e, newPage) => {
                                        setSearchArgs({ ...searchArgs, skip: newPage * searchArgs.first })
                                    }}
                                    onRowsPerPageChange={e => {
                                        setSearchArgs({ ...searchArgs, first: e.target.value })
                                    }}
                                    />
                            </>
                        )}
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    )
}

export const UserList = () => {
    const history = useHistory()
    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 350)
    const [searchArgs, setSearchArgs] = useState({
        q: debouncedSearchTerm,
        skip: 0,
        first: 10,
    })
    useEffect(() => {
        setSearchArgs(s => ({...s, q: debouncedSearchTerm, skip: 0 }))
    }, [debouncedSearchTerm])
    const { data, loading } = useQuery(gql`
        query ListUsersQuery ( $searchArgs: SearchUserInput! ) {
            users ( searchArgs: $searchArgs ) {
                page
                count
                results {
                    id
                    username
                    title
                    firstName
                    lastName
                    mobile

                    isActive
                    notifyEmailSent
                    hasPassword

                    organization {
                        id
                        shortName
                        fullName
                        breastCode
                        isSite
                    }
                }
            }
        }
    `, {
        variables: { searchArgs },
        fetchPolicy: 'no-cache',
    })
    const users = data?.users?.results || [];
    return (
        <Container>
            {/**
            <Card sx={{ my: 2 }}>
                <CardHeader
                    action={
                        <IconButton>
                            <MoreHorizIcon />
                        </IconButton>
                    }
                    title="Users title"
                    // subheader="this is the subheader"
                    />
                <CardContent>
                    Foobar.
                </CardContent>
            </Card>
            */}

            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Stack direction="column" spacing={2}>
                        <MyTextField
                            label={`Search for a user`}
                            value={searchTerm}
                            onChange={e => {
                                setSearchTerm(e.target.value)
                            }}
                            autoFocus
                            />
                        {loading ? <Loading /> : (
                            <>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>User</TableCell>
                                            <TableCell>Username</TableCell>
                                            <TableCell>Organization</TableCell>
                                            <TableCell>Breast code</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.map(u => (
                                            <TableRow
                                                key={u.id}
                                                hover
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    history.push(`/operations/users/${u.id}/profile`)
                                                }}>
                                                <TableCell>{u.title} {u.firstName} {u.lastName}</TableCell>
                                                <TableCell>{u.username}</TableCell>
                                                <TableCell>{u.organization.fullName}</TableCell>
                                                <TableCell>{u.organization.isSite ? u.organization.breastCode : <Box component="span" sx={{ color: 'text.disabled'}}>N/A</Box>}</TableCell>
                                                <TableCell>
                                                    {u.isActive ? (
                                                        (u.notifyEmailSent && u.hasPassword)
                                                            ? <Box component="span" sx={{ color: 'success.main' }}>Active</Box>
                                                            : u.notifyEmailSent
                                                            ? <Box component="span" sx={{ color: 'warning.main' }}>Pending</Box>
                                                            : <Box component="span" sx={{ color: 'warning.main' }}>BIG action required</Box>
                                                    ) : (
                                                        <Box component="span" sx={{ color: 'error.main' }}>DISABLED</Box>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={data.users.count}
                                    page={data.users.page}
                                    rowsPerPage={searchArgs.first}
                                    rowsPerPageOptions={[ 10, 25, 50, 100, { value: -1, label: 'ALL' }]}
                                    onPageChange={(e, newPage) => {
                                        setSearchArgs({ ...searchArgs, skip: newPage * searchArgs.first })
                                    }}
                                    onRowsPerPageChange={e => {
                                        setSearchArgs({ ...searchArgs, first: e.target.value })
                                    }}
                                    />
                            </>
                        )}
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    )
}


// const TabLink = ({ label, to, ...props }) => {
//     const match = useRouteMatch({
//         path: to,
//         exact: true,
//     })
// 
//     return (
//         <Tab
//             component={NavLink}
//             to={to}
//             value={to}
//             label={label}
//             selected={match ? true : false}
//             {...props}
//         />
//     )
// }


// const useNavTabs = (routes, defaultRoute) => {
//     const match = useRouteMatch(routes)
//     return match?.path || defaultRoute
// }


export const OrganizationProfile = () => {
    const { id } = useParams()
    const history = useHistory()
    const { data, loading, error } = useQuery(gql`
        query OrganizationQuery ( $id: ID! ) {
            organization ( id: $id ) {
                id
                shortName
                fullName
                breastCode
                isSite
                category
                bncCode
                biguserSet {
                    id
                    username
                    title
                    firstName
                    lastName
                    isActive
                }
            }
        }`, {
        variables: { id },
    })
    if (loading) return <Loading />
    if (error) return 'error...'
    const { shortName, fullName, breastCode, isSite, category, bncCode } = data.organization
    return (
        <Container>
            <Card>
                <CardContent>
                    <Stack direction="column" spacing={2}>
                        <Box>
                            <Typography variant="h6">
                                {fullName}
                                {shortName !== fullName && (
                                    <Box component="span" sx={{ color: 'text.disabled' }}> | {shortName}</Box>
                                )}
                            </Typography>

                            <Box>
                                {isSite ? (
                                    <>SITE | breast code: <strong>{breastCode}</strong> | BNC code: <strong>{bncCode}</strong></>
                                ) : (
                                    <>{category}</>
                                )}
                            </Box>
                        </Box>

                        <Divider />

                        <Typography variant="span">Users from this organization</Typography>
                        <Table size="small">
                            <TableHead>
                                <TableCell>Login</TableCell>
                                <TableCell>Title and name</TableCell>
                                <TableCell>Active</TableCell>
                            </TableHead>
                            <TableBody>
                                {(data?.organization?.biguserSet || []).map(u => (
                                    <TableRow key={u.id} hover sx={{ cursor: 'pointer' }} onClick={() => {
                                        history.push(`/operations/users/${u.id}/profile`)
                                    }}>
                                        <TableCell>{u.username}</TableCell>
                                        <TableCell>{u.title} {u.firstName} {u.lastName}</TableCell>
                                        <TableCell>{u.isActive ? 'Yes' : 'No'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        <Stack direction="row" justifyContent="space-between">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    history.push(`/operations/organizations/${id}/edit`)
                                }}
                                >
                                Edit
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    history.goBack()
                                }}
                                >
                                Cancel
                            </Button>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    )
}


export const UserProfile = () => {
    const { id } = useParams()
    const { me } = useMe()
    const history = useHistory()
    const { data, loading, error } = useQuery(gql`
        query UserQuery ( $id: ID! ) {
            user ( id: $id ) {
                id
                username
                firstName
                lastName
                title
                mobile
                email
                isActive
                groups {
                    id
                    name
                }
                organization {
                    id
                    category
                    breastCode
                    bncCode
                    fullName
                }
            }
        }`, {
        variables: { id },
    })

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = e => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const [ getUserToken ] = useMutation(gql`
        mutation GetUserTokenMutation ( $id: ID! ) {
            getUserToken( id: $id ) {
                token
            }
        }
    `)

    if (loading) return <Loading />
    if (error) return 'error...'
    const { isActive, username, firstName, lastName, title, mobile, email, organization } = data.user
    const roleNames = data.user.groups.map(g => g.name)

    return (
        <Container>
            <Card>
                <CardContent>
                    <Stack direction="column" spacing={2}>
                        <Box>
                            <Typography variant="h6">
                                {title} {firstName} {lastName} <Box component="span" sx={{ color: 'text.disabled' }}>| {username}</Box>
                            </Typography>
                            <Typography variant="body1">User account role(s): <strong>{roleNames.join(', ')}</strong></Typography>
                        </Box>

                        {(!isActive) && (
                            <Alert severity="error">This user account is disabled.</Alert>
                        )}

                        <Box>
                            <Typography variant="body1">
                                {organization.fullName} <MyNavLink to={`/operations/organizations/${organization.id}/profile`}>(view)</MyNavLink>
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="body1">Telephone: {mobile ? mobile : 'N/A'}</Typography>
                            <Typography variant="body1">
                                Email: <MyLink href={`mailto:${email}`}>{email}</MyLink>
                            </Typography>
                        </Box>

                        <Stack direction="row" justifyContent='space-between'>
                            <Stack direction="row" spacing={1}>
                                <NavLink
                                    component={Button}
                                    variant="contained"
                                    to={`/operations/users/${id}/edit`}
                                    >Edit</NavLink>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleClick}
                                    startIcon={<HandymanIcon />}
                                    >
                                    Actions
                                </Button>
                                <Menu
                                  id="fade-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  TransitionComponent={Fade}
                                >
                                    <MenuItem onClick={handleClose}>Send account activation email</MenuItem>
                                    <MenuItem onClick={handleClose}>Disable account</MenuItem>
                                    {me.isDeveloper && (
                                        <>
                                            <Divider />
                                            <MenuItem onClick={async () => {
                                                const { data } = await getUserToken({ variables: { id } });
                                                const token = data?.getUserToken?.token
                                                localStorage.setItem(Settings.ACCESS_TOKEN, token);
                                                history.push(Settings.HOME_PAGE);
                                                window.location.reload();
                                            }}>Impersonate account</MenuItem>
                                        </>
                                    )}
                                </Menu>
                            </Stack>
                            <Button variant="outlined" color="secondary" onClick={() => {
                                history.goBack()
                            }}>Cancel</Button>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    )
}

export const EditUser = () => {
    const { id } = useParams()
    const history = useHistory()
    const [ organizations, setOrganizations ] = useState([])
    const [ groups, setGroups ] = useState([])
    const [ formData, setFormData ] = useState({})

    const { data, loading, error } = useQuery(gql`
        query UserQuery ( $id: ID! ) {
            user ( id: $id ) {
                id
                username
                firstName
                lastName
                title
                mobile
                email
                organization {
                    id
                }
                groups {
                    id
                }
            }
            allOrganizations {
                id
                shortName
                fullName
                breastCode
                isSite
            }
            groups {
                id
                name
            }
        }`, {
        variables: { id },
    })

    useEffect(() => {
        if (loading) return;
        const { user, allOrganizations } = data;
        const { username, firstName, lastName, title, mobile, email, organization } = user;
        setOrganizations(allOrganizations)
        setGroups(data.groups)
        setFormData({
            id,
            username,
            firstName,
            lastName,
            title,
            mobile,
            email,
            organization: organization.id,
            groups: user.groups.map(g => g.id),
        })
    }, [id, data, loading])

    if (loading) return <Loading />
    if (!formData?.id) return <Loading />
    if (error) return 'error'

    return (
        <Container>
            <Paper sx={{ p: 2, pt: 4 }}>

                {/**
                <pre>{JSON.stringify(formData, null, 2)}</pre>
                */}

                <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                    <MyTextField
                        sx={{ maxWidth: 100 }}
                        label="Title"
                        value={formData.title}
                        onChange={e => {
                            setFormData({ ...formData, title: e.target.value })
                        }}
                        select
                        >
                        <MenuItem value={`Mr`}>Mr</MenuItem>
                        <MenuItem value={`Ms`}>Ms</MenuItem>
                        <MenuItem value={`Dr`}>Dr</MenuItem>
                        <MenuItem value={`Prof`}>Prof</MenuItem>
                    </MyTextField>

                    <MyTextField
                        label="First name"
                        sx={{ mb: 2 }}
                        value={formData.firstName}
                        onChange={e => {
                            setFormData({ ...formData, firstName: e.target.value })
                        }}
                        />
                    <MyTextField
                        label="Last name"
                        value={formData.lastName}
                        onChange={e => {
                            setFormData({ ...formData, lastName: e.target.value })
                        }}
                        />
                </Stack>

                {/**
                <MyTextField
                    sx={{ mb: 2 }}
                    label="Organization"
                    value={formData.organization}
                    onChange={e => {
                        setFormData({ ...formData, organization: e.target.value })
                    }}
                    select
                    >
                    {organizations.map(o => {
                        return (
                           <MenuItem value={o.id}><strong>{o.fullName}</strong>&nbsp;|&nbsp;{o.shortName}</MenuItem>
                        )
                    })}
                </MyTextField>
                */}

                <Autocomplete
                    disablePortal
                    options={organizations.map(o => ({id: o.id, label: o.fullName}))}
                    sx={{ width: '100%', mb: 2 }}
                    value={formData.organization ? organizations.filter(o => o.id === formData.organization)[0].fullName : null}
                    // onChange={e => {
                    //     setFormData({ ...formData, organization: e.target.value })
                    // }}
                    onChange={(e, newValue) => {
                        setFormData({ ...formData, organization: newValue?.id })
                    }}
                    renderInput={params => <TextField {...params}
                        label="Organization"
                        />}
                    />

                <MyTextField
                    sx={{ mb: 2 }}
                    label="Username"
                    value={formData.username}
                    onChange={e => {
                        setFormData({ ...formData, username: e.target.value })
                    }}
                    />

                <Alert severity="info" sx={{ mb: 3 }}>
                    Historically, the username has been composed as <strong>&lt;full last name&gt;&lt;first name initial&gt;</strong>.
                    You may use any username so long as it is unique.
                    This could be an <strong>email address</strong> or telephone number.
                </Alert>

                <FormControl sx={{ mb: 2, width: '100%' }}>
                    <InputLabel id="groups-label">User account roles</InputLabel>
                    <Select
                        labelId="groups-label"
                        id="groups"
                        value={formData.groups || []}
                        input={<OutlinedInput label="User account roles" />}
                        renderValue={(selected) => {
                            // selected.join(', ')
                            return groups
                                .filter(g => selected.indexOf(g.id) > -1)
                                .map(g => g.name)
                                .join(', ')
                                ;
                        }}
                        onChange={e => {
                            setFormData({ ...formData, groups: e.target.value })
                        }}
                        multiple
                        >
                            {groups.map(g => (
                                <MenuItem key={g.id} value={g.id}>
                                    <Checkbox checked={formData.groups.indexOf(g.id) > -1} />
                                    <ListItemText primary={g.name} />
                                </MenuItem>
                            ))}
                        </Select>
                </FormControl>

                <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                    <MyTextField
                        label="Email address"
                        value={formData.email}
                        onChange={e => {
                            setFormData({ ...formData, email: e.target.value })
                        }}
                        />
                    <MyTextField
                        label="Telephone number"
                        value={formData.mobile}
                        onChange={e => {
                            setFormData({ ...formData, mobile: e.target.value })
                        }}
                        />
                </Stack>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" color="primary" sx={{ mr: 1 }} onClick={() => {
                        console.log(formData)
                    }}>Save changes</Button>
                    <Button variant="outlined" color="secondary" onClick={() => {
                        history.goBack()
                    }}>Cancel</Button>
                </Box>
            </Paper>
        </Container>
    )
}


export const OperationsPage = () => {
    return (
        <>
        <Container>
            <AppBar position="static" color="primary">
                <Container>
                    <Toolbar disableGutters>
                        <AppBarButton to={`/operations/users`} label="Users" />
                        <AppBarButton to={`/operations/organizations`} label="Organizations" />
                        <AppBarButton to={`/operations/data_imports`} label={`Data Imports`} />
                    </Toolbar>
                </Container>
            </AppBar>
        
            {/**
            <Tabs value={tabValue}>
                <TabLink value={`/operations/users`} to={`/operations/users`} label="Users" />
                <TabLink value={`/operations/organizations`} to={`/operations/organizations`} label="Organizations" />
            </Tabs>

            <ul>
                <li><Link to="/operations/users">Users</Link></li>
                <li><Link to="/operations/organizations">Organizations</Link></li>
            </ul>
            */}

        </Container>

        <Switch>
            <Route exact path={`/operations/users`}><UserList /></Route>
            <Route path={`/operations/users/:id/profile`}><UserProfile /></Route>
            <Route path={`/operations/users/:id/edit`}><EditUser /></Route>
            <Route exact path={`/operations/organizations`}><OrganizationList /></Route>
            <Route path={`/operations/organizations/:id/profile`}><OrganizationProfile /></Route>
            <Route exact path={`/operations`}><Redirect to={`/operations/users`} /></Route>
        </Switch>
        </>
    )
}


export const ImpersonateUser = () => {
    const history = useHistory();

    const [searchTerm, setSearchTerm] = useState("")
    const debouncedSearchTerm = useDebounce(searchTerm, 350)
    const [searchArgs, setSearchArgs] = useState({
        q: debouncedSearchTerm,
        skip: 0,
        first: 10,
    })

    useEffect(() => {
        setSearchArgs(s => ({...s, q: debouncedSearchTerm, skip: 0 }))
    }, [debouncedSearchTerm])

    const { data, loading, error } = useQuery(gql`
        query ListImpersonableUsersQuery ( $searchArgs: SearchUserInput! ) {
            users ( searchArgs: $searchArgs ) {
                page
                count
                results {
                    id
                    username
                    title
                    firstName
                    lastName
                    mobile
                    organization {
                        id
                        shortName
                        fullName
                        breastCode
                        isSite
                    }
                }
            }
        }
    `, {
        variables: { searchArgs },
        fetchPolicy: 'no-cache',
    })

    const [ getUserToken ] = useMutation(gql`
        mutation GetUserTokenMutation ( $id: ID! ) {
            getUserToken( id: $id ) {
                token
            }
        }
    `)

    if (error) return 'error'

    const users = data?.users?.results;

    return (
        <>
            <Container>
                <Card>
                    <CardContent>
                        <Stack direction="column" spacing={2}>
                            <MyTextField
                                label={`Search for user to impersonate`}
                                value={searchTerm}
                                onChange={e => {
                                    setSearchTerm(e.target.value)
                                }}
                                autoFocus
                                />

                            {loading
                                ? <Loading />
                                : (
                                    <>
                                        <Table size="small">
                                            <TableHead>
                                                <TableCell>User</TableCell>
                                                <TableCell>Username</TableCell>
                                                <TableCell>Organization</TableCell>
                                                <TableCell>Breast code</TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {users.map(u => (
                                                    <TableRow key={u.id} hover sx={{ cursor: 'pointer' }} onClick={async () => {
                                                        const { data } = await getUserToken({ variables: { id: u.id } });
                                                        const token = data?.getUserToken?.token
                                                        localStorage.setItem(Settings.ACCESS_TOKEN, token);
                                                        history.push(Settings.HOME_PAGE);
                                                        window.location.reload();
                                                    }}>
                                                        <TableCell>{u.title} {u.firstName} {u.lastName}</TableCell>
                                                        <TableCell>{u.username}</TableCell>
                                                        <TableCell>{u.organization.fullName}</TableCell>
                                                        <TableCell>{u.organization.breastCode}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            count={data.users.count}
                                            page={data.users.page}
                                            rowsPerPage={searchArgs.first}
                                            rowsPerPageOptions={[ 10, 25, 50, 100, { value: -1, label: "ALL" }]}
                                            onPageChange={(e, newPage) => {
                                                setSearchArgs({ ...searchArgs, skip: newPage * searchArgs.first })
                                            }}
                                            onRowsPerPageChange={e => {
                                                setSearchArgs({ ...searchArgs, first: e.target.value })
                                            }}
                                            />
                                    </>
                                )
                            }
                        </Stack>
                    </CardContent>
                </Card>
            </Container>
        </>
    )
}