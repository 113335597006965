
import { Settings } from '../Settings'
import { useMe } from '../providers/Me'

import * as React from 'react';
import { useEffect, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Brightness6Icon from '@mui/icons-material/Brightness6';

import ListItem from '@mui/material/ListItem';



import {
    Menu,
    MenuItem,
    Stack,
} from '@mui/material'

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CodeIcon from '@mui/icons-material/Code';
import HandymanIcon from '@mui/icons-material/Handyman';
import CallIcon from '@mui/icons-material/Call';
import ArticleIcon from '@mui/icons-material/Article';
import ListItemButton from '@mui/material/ListItemButton';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useLocation } from 'react-router-dom';
import { useMyCurrentTheme } from '../providers/MyThemeProvider';


const drawerWidth = 240;


const ListItemLink = props => {
    const [selected, setSelected] = useState(false)
    const location = useLocation()
    const { to } = props
    useEffect(() => {
        setSelected(location.pathname.startsWith(to))
    }, [location, to])
    return (
        <ListItemButton
            component={Link}
            to={to}
            selected={selected}
            {...props}
        />
    )
}


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);


export const Navigation = props => {
    const { theme: themeName, setTheme } = useMyCurrentTheme()

    const theme = useTheme();
    const [open, setOpen] = useState(true)
    const { me } = useMe()
    const [anchorEl, setAnchorEl] = useState(null)
    const openProfileMenu = event => setAnchorEl(event.currentTarget)
    const closeMenu = () => setAnchorEl(null)
    const isMenuOpen = Boolean(anchorEl)
    const handleDrawerOpen = () => setOpen(true)
    const handleDrawerClose = () => setOpen(false)
    const menuId = 'top-navbar-dropdown-menu'
    const NavbarMenu = () => (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={closeMenu}
        >
            <MenuItem onClick={() => {
                localStorage.removeItem(Settings.ACCESS_TOKEN)
                window.location.reload()
            }}>
                Logout
          </MenuItem>
        </Menu>
    )

    const showQueries = me?.isNurse
        || me?.isInvestigator
        || me?.isMonitor
        || me?.isGroupMonitor
        ;

    const showActivities = me?.isNurse
        || me?.isInvestigator
        || me?.isGroupMonitor
        || me?.isLabMember
        || me?.isPathologist
        || me?.isPathologistPythia
        ;

    const showPatientRegistration = me?.isNurse || me?.isInvestigator;

    const showPatients = me?.isNurse
        || me?.isInvestigator
        || me?.isMonitor
        || me?.isGroupMonitor
        || me?.isLabMember
        || me?.isMabMember
        || me?.isPathologist
        || me?.isPathologistPythia
        ;

    return (
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}>
                        <IconButton
                            color="inherit"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Stack
                            direction="row"
                            spacing={3}
                            sx={{
                                alignItems: 'center',
                                flexGrow: 1
                            }}
                        >
                            <Box>
                                <Typography variant="h6" noWrap component="div">
                                    AURORA Platform
                                </Typography>
                            </Box>
                        </Stack>
                        <IconButton
                            size="large"
                            edge="end"
                            onClick={openProfileMenu}
                            color="inherit"
                        >
                            <Typography variant="body1">
                                {me?.firstName} {me?.lastName}
                            </Typography>
                            &nbsp;
                            <AccountCircle />
                        </IconButton>
                    </Stack>
                </Toolbar>
            </AppBar>

            <NavbarMenu />

            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>

                <Divider />

                <List>
                    {me.isMonitor && (
                        <ListItemLink to="/dashboard">
                            <ListItemIcon><DashboardIcon /></ListItemIcon>
                            <ListItemText primary={`Dashboard`} />
                        </ListItemLink>
                    )}

                    {showPatientRegistration && (
                        <ListItemLink to="/registration">
                            <ListItemIcon><GroupAddIcon /></ListItemIcon>
                            <ListItemText primary={`New Patient`} />
                        </ListItemLink>
                    )}

                    {showPatients && (
                        <ListItemLink to="/patients">
                            <ListItemIcon><PeopleAltIcon /></ListItemIcon>
                            <ListItemText primary={`Patients`} />
                        </ListItemLink>
                    )}

                    {showActivities && (
                        <ListItemLink to="/activities">
                            <ListItemIcon><FormatListNumberedIcon /></ListItemIcon>
                            <ListItemText primary={`Activities`} />
                        </ListItemLink>
                    )}

                    {showQueries && (
                        <ListItemLink to="/queries">
                            <ListItemIcon><ReportGmailerrorredIcon /></ListItemIcon>
                            <ListItemText primary={`Queries`} />
                        </ListItemLink>
                    )}

                    <ListItemLink to="/documents">
                        <ListItemIcon>
                            <ArticleIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Documents`} />
                    </ListItemLink>

                    <ListItemLink to="/help">
                        <ListItemIcon>
                            <CallIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Contact`} />
                    </ListItemLink>

                    <Divider />

                    {me.isOperator && (
                        <>
                            <ListItemLink to="/operations">
                                <ListItemIcon>
                                    <HandymanIcon />
                                </ListItemIcon>
                                <ListItemText primary={`Operations`} />
                            </ListItemLink>
                            <Divider />
                        </>
                    )}

                    {me.isDeveloper && (
                        <>
                            <ListItemLink to="/users/impersonate">
                                <ListItemIcon>
                                    <CodeIcon />
                                </ListItemIcon>
                                <ListItemText primary={`Dev tools`} />
                            </ListItemLink>

                            <ListItemButton
                                onClick={() => {
                                    setTheme(themeName === 'dark' ? 'light' : 'dark')
                                }}
                                >
                                <ListItemIcon>
                                    <Brightness6Icon />
                                </ListItemIcon>
                                <ListItemText primary={themeName === 'light' ? `Dark mode` : `Light mode`} />
                            </ListItemButton>

                            <Divider />
                        </>
                    )}

                    <ListItem button onClick={() => {
                        localStorage.removeItem(Settings.ACCESS_TOKEN)
                        window.location.reload()
                    }}>
                        <ListItemIcon><LogoutIcon /></ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                    </ListItem>
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                {props.children}
            </Main>
        </Box>
    )
}