const hostname = window.location.hostname

const backendUrl = (hostname === "platform.aurora-mbc.org")
    ? "https://platform.aurora-mbc.org"
    : hostname === 'training.aurora-mbc.org'
    ? 'https://training.aurora-mbc.org'
    : hostname === 'platform-v2-demo.aurora-mbc.org'
    ? 'https://platform-v2-demo.aurora-mbc.org'
    : `http://${hostname}:89`;

const graphqlUri = `${backendUrl}/graphql`

// these will be included in the forms but hidden from the user. they're
// used for legacy reasons.
export const HIDDEN_ATTRIBUTES = [
    'icf_1_0_signed',
    'icf_1_1_signed',
    'icf_2_0_signed',
    'icf_3_0_signed',
    'icf_4_0_signed',
]

export const Settings = {
    MEDIA_URL: `${backendUrl}/media/`,
    ACCESS_TOKEN: 'aurora.accessToken',
    GRAPHQL_URI: graphqlUri,
    // LOGIN_URL: `${backendUrl}/accounts/google/login/`,
    HOME_PAGE: '/patients',
    BACKEND_URL: backendUrl,
}